
$normal-background-colour: rgb(21, 33, 33);
$neon-background-colour: rgb(106, 155, 233);
$neon-glow-colour: blueviolet;
$normal-text-colour: whitesmoke;
$neon-text-colour: darkslategray;

.neon-button {
    position: relative;
    display: inline-block;
    padding: 10px;
    margin: 5px 15px;
    vertical-align: middle;

    color: $normal-text-colour;
    background-color: $normal-background-colour;
    border-radius: 3%;
    // text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none!important;
    overflow: hidden;
    transition: all 0.3s;

    cursor: pointer;

    &:hover {
        color: $neon-text-colour;
        background: $neon-background-colour;
        box-shadow: 0 0 10px $neon-glow-colour, 0 0 40px $neon-glow-colour, 0 0 80px $neon-glow-colour;
        transition-delay: 0.3s;

        span {
            &:nth-child(1) {
                left: 100%;
                transition: 0.5s;
            }
            &:nth-child(2) {
                top: 100%;
                transition: 0.5s;
            }
            &:nth-child(3) {
                right: 100%;
                transition: 0.5s;
            }
            &:nth-child(4) {
                bottom: 100%;
                transition: 0.5s;
            }
        }
    }
    span {
        position: absolute;
        display: block;

        &:nth-child(1) {
            top: 0;
            left: -100%;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, transparent, rgb(105, 187, 255))!important;
        
            transition: 0.5s;
        }
        &:nth-child(2) {
            top: -100%;
            right: 0%;
            width: 100%;
            height: 2px;
            background: linear-gradient(180deg, transparent, rgb(105, 187, 255))!important;
        
        }
        &:nth-child(3) {
            bottom: 0;
            right: -100%;
            width: 100%;
            height: 2px;
            background: linear-gradient(270deg, transparent, rgb(105, 187, 255))!important;
        
            transition: 0.5s;
        }
        &:nth-child(4) {
            bottom: -100%;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(0deg, transparent, rgb(105, 187, 255))!important;
        
        }
    }
}


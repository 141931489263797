.user-profile-card {
  background-color: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 12px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  .user-profile-image {
    width: 50%;
    height: auto;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
  }
}

.user-profile-card-divider {
  height: 1px;
  width: 100%;
  margin: 10px auto !important;
  color: #333; /* old IE */
  background-color: rgba(51, 51, 51, 0.212);
}

.user-profile {
  background-size: cover;
  padding: 32px;
  box-shadow: inset 0 -10px 10px -10px #0000009a;
}

.main-card {
  padding: 5px !important;
  &.top-padded {
    margin-top: 20px;
  }
  border-radius: 10px !important;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1) !important;
  height: 100%;
}

.card-list {
  display: flex;
  padding: 5rem;
  overflow-x: scroll;
  max-width: 65vw;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: whitesmoke;
  }
  &::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 10px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(83, 83, 83, 0.25);
  }

  &::-webkit-scrollbar-track {
    background: whitesmoke;
  }

  .card {
    width: 400px;
    min-width: 250px;
    padding: 1.5rem;
    border-radius: 16px;
    background: #fdfdfd;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    margin: 0;
    scroll-snap-align: start;
    clear: both;
    position: relative;

    .information-section {
      margin: 15px 0px;
      color: darkslategray;
    }
  }

  .card:focus-within ~ .card,
  .card:hover ~ .card {
    transform: translateX(130px);
  }

  .card:hover {
    transform: translateY(-1rem);
  }

  .card:not(:first-child) {
    margin-left: -130px;
  }

  .card-header {
    padding: 0;
  }

  .card-header p {
    font-size: 14px;
    margin: 0 0 1rem;
    color: #7a7a8c;
  }

  .card-header h2 {
    text-align: center;
    font-size: 20px;
    margin: 0.25rem 0 auto;
    text-decoration: none;
    color: inherit;
    border: 0;
    display: inline-block;
    cursor: pointer;
  }

  .card-header h2:hover {
    background: darkslategray;
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .card-author {
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    position: relative;
  }

  .author-avatar {
    grid-area: auto;
    align-self: start;
    position: relative;
    box-sizing: border-box;
  }

  .author-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: grayscale(100%);
    display: block;
    overflow: hidden;
    margin: 16px 10px;
  }

  .author-name {
    grid-area: auto;
    box-sizing: border-box;
  }

  .author-name-prefix {
    font-style: normal;
    font-weight: 700;
    color: #3f3f3f;
  }

  .half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    stroke: #9589ff;
    stroke-width: 8;
    stroke-linecap: round;
    pointer-events: none;
  }

  .tags {
    margin: 1rem 0 2rem;
    padding: 0.5rem 0 1rem;
    line-height: 2;
    margin-bottom: 0;
  }

  .tags a,
  .tags span {
    font-style: normal;
    font-weight: 700;
    font-size: 0.5rem;
    color: #7a7a8c;
    text-transform: uppercase;
    font-size: 0.66rem;
    border: 3px solid darkslategray;
    border-radius: 2rem;
    padding: 0.2rem 0.85rem 0.25rem;
    position: relative;

    background: darkslategray;
    color: white;

    &.approve {
      margin-right: 12px;
      &:hover {
        background: limegreen;
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }

    &.reject {
      margin-right: 12px;
      &:hover {
        background: crimson;
        color: white !important;
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }

    &.message {
      margin-right: 12px;
      &:hover {
        background: deepskyblue;
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }

    &.profile {
      margin-right: 12px;
      text-decoration: none;
      &:hover {
        background: gray;
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }
  }

  .disabled {
    &:hover {
      background: black !important;
    }
  }
}


h3 {
    text-align: center;
}

.title-hr {
    width: 30%;
    margin: 0 auto;
    hr {
        border: 0;
        height: 1px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    }
}


@import '~bootstrap/scss/bootstrap';
@import './components/index';

// Homepage styles.
.video-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;

  iframe {
    height: auto;
    min-height: 360px;
    max-width: 640px;
    width: 100%;
  }
}

// Button style reset.
.btn-reset-style {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.navItemCenter {
    margin: auto!important;
    margin-top: 10px;
    width: 100%;
}

// For centering the dark mode switch
.navItemSwitchCenter {
    margin: auto;
    width: auto;
}

.navItemText {
    text-align: center;
}

.nav-item .avatar {
    display: block;
    margin: 3px auto;
}

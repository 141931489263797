.title {
  color: black;
}

.card {
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  padding: 24px;

  .divider {
    border: none;
    height: 1px;
    color: #333; /* old IE */
    background-color: rgba(51, 51, 51, 0.212);
  }
}

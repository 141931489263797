.paginator {
    ul {
        // Centering the paginator:
        padding: 10px;
        display: flex;
        justify-content: center;

        li {
            display: inline-block;
            padding-left: 0;
            list-style: none;

            border-radius: 5px;

            &.selected {
                a {
                    color: white;
                    background-color: darkslategray;
                }
            }

            a, span {

                border-radius: 5px;
                position: relative;
                float: left;
                padding: 6px 12px;
                line-height: 1.42857143;
                text-decoration: none;
                color: #2c689c;
                background-color: #fff;
                border: 1px solid #ddd;
                margin-left: -1px;
            }
            a:hover {
                background-color:  #218838;
                color: white;
                transition: 0.4s all;
            }
            &.active {
                a {
                    color: #fff;
                    background-color: #218838;
                    border-color: #1e7e34;
                    border-radius: 5px;
                }
            }
            // Disabling prev/next buttons
            &:first-child, &:last-child {
                a, span {
                    margin-left: 0;
                    padding: 0px;
                    border-bottom-left-radius: 4px;
                    border-top-left-radius: 4px;
                    display: none!important;
                }
            }
        }
    }
}



.title {
  color: black;
  text-align: left;
}

.card {
  border-radius: 10px !important;
  padding: 24px;

  .divider {
    height: 1px;
    color: #333; /* old IE */
    background-color: rgba(51, 51, 51, 0.212);
  }
}

.button {
  border-radius: 50%;
  width: 48px !important;
  height: 48px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-in-out;

  &:hover {
    background: rgb(226, 220, 220);
  }
}

.spaced {
    padding: 20px;
}

.link-preview-card {
    a {
        text-decoration: none!important;
        &:hover {
            color: grey;
        }
    }
}

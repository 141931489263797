.empty-chat-indicator {
    width: 94%;
    height: 45%;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding-top:20%;
    font-size: 200%;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

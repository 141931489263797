.uploadInput {
  display: none !important;
}

.uploadInputLabel {
  display: inline-block;
  padding: 6px 14px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  background: #8f8fffe6;
  cursor: pointer;
}

.divider {
  height: 1px;
  color: #333; /* old IE */
  background-color: rgba(51, 51, 51, 0.212);
}

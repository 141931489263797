.externalButton {
    width: 60%;
    margin: 0 auto;
    button {
        width: 100%;
    }
}

.alternativeText {
    padding: 0;
    margin: 0;
    padding-top: 20px;
    text-align: center;
}
